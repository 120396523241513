<template>
    <v-virtual-scroll
        class="cols-12"
        :bench="benched"
        :items="stores"
        :height="scrollerHeight"
        item-height="88"
        @scroll.native="handleScroll"
        ref="virtualScroller"
    >
        <template v-slot:default="{ item }">
            <v-list-item
                link
                three-line
                @click.stop="selected(item.IDCliente)"
                :disabled="item.CodiceNazionaleCoopGLN.length === 0"
            >
                <div class="d-flex align-center">
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark>
                            mdi-storefront
                        </v-icon>
                    </v-list-item-avatar>
                </div>
                <v-list-item-content>
                    <v-list-item-title
                        v-text="
                            `${item.AliasCliente} - ${item.Insegna}`
                        "
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-text="item.IndirizzoInsegna"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                        v-text="
                            `${item.CAPInsegna} ${item.ComuneInsegna} (${item.SiglaProvinciaInsegna})`
                        "
                    ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon v-if="item.CodiceNazionaleCoopGLN.length === 0">
                        <v-icon>mdi-alert</v-icon>
                    </v-btn>
                    <PromoCount :storeId="item.IDCliente" v-else></PromoCount>
                </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
export const PromoCount = () => import("./PromoCount");

import { mapMutations, mapState, mapActions } from "vuex";
import * as storeActions from "../../store/actions/store";
import * as appActions from "../../store/actions/app";

export default {
    props: {
        stores: {
            type: Array,
            required: true
        },
        scrollerHeight: {
            type: Number,
            required: true
        }
    },

    components: {
        PromoCount
    },

    /*     watch: {
        scrollerHeight: (newValue) => {
            console.log(newValue)
        }
    }, */

    data: () => ({
        benched: 4
    }),

    computed: {
        ...mapState({
            getCacheCount: state => state.storeModule.cache,
            getScrollPosition: state => state.appModule.farmStoresScrollPosition
        }),

        // promoCount() {
        //     return storeId => {
        //         const count = this.getCacheCount[storeId]
        //         if (count === undefined) {
        //             return this.getPromoCount(storeId).then(count => count)
        //         }
        //         else {
        //             return count
        //         }

        //     }
        // },
        scrollPosition: {
            get() {
                return this.getScrollPosition;
            },
            set(value) {
                this.setListScrollPosition(value);
            }
        }
    },

    methods: {
        ...mapMutations({
            resetStore: storeActions.STORE_RESET_STORE,
            setListScrollPosition: appActions.APP_SETSTORES_SCROLLPOSITION
        }),
        // ...mapActions({
        //     getPromoCount: storeActions.STORE_GET_PROMO_COUNT
        // }),

        selected(id) {
            this.$emit("selected", id);
        },

        handleScroll(event) {
            this.scrollPosition = event.target.scrollTop;
        }
    },

    beforeMount() {
        /* console.log(this.scrollerHeight) */
        this.resetStore();
    },

    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.$refs.virtualScroller !== undefined) {
                    this.$refs.virtualScroller.$el.scrollTop = this.scrollPosition;
                }
            }, 10);
        });
    }
};
</script>

<style scoped>

.v-virtual-scroll {
    padding-bottom: 128px;
}
</style>
